import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import HamburgerMenu from "react-hamburger-menu";
import logo from "../../../images/LOGO_ERD.png";
import ContentXs from "./ContentXs";
import Footer from "../../Footer/Footer";
import "./MenuXs.scss";

const MenuXs = ({
  defaultState,
  onCloseCb,
  extraClassName = "",
  onAnimationEnd,
}) => {
  const [openHam, setOpenHam] = useState(defaultState || false);

  const handleHamClick = () => {
    setTimeout(() => {
      setOpenHam(!openHam);
    }, 100);
  };

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current && !openHam && onCloseCb) {
      onCloseCb();
    }
  }, [openHam]);

  useEffect(() => {
    const htmlNode = document.querySelector("html");
    if (openHam) {
      htmlNode.style.overflow = "hidden";
    } else {
      htmlNode.style.overflow = null;
    }

    return () => {
      htmlNode.style.overflow = null;
    };
  }, [openHam]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const handleLinkClick = (pathname) => {
    const pathnames = [pathname, `${pathname}/`];
    if (pathnames.some((path) => path === window.location.pathname)) {
      setOpenHam(false);
    }
  };

  return (
    <div
      className={`${extraClassName} selectorBody`}
      onAnimationEnd={() => onAnimationEnd && onAnimationEnd()}
    >
      <div className="menuHamburger">
        <Link to="/" className="name-menu">
          <img src={logo} alt="logo" style={{ width: 74 }} />
        </Link>
        <HamburgerMenu
          isOpen={openHam}
          menuClicked={() => handleHamClick()}
          color="#303546"
          width={27}
          height={16}
          strokeWidth={1}
          animationDuration={0.6}
          className="hamburger"
        />
      </div>
      {openHam && (
        <>
          <div className="nav-mobile-container">
            <ContentXs onClick={handleLinkClick} />
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default MenuXs;
