import React from 'react'
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { IoLogoInstagram } from 'react-icons/io'
import { TiSocialPinterest } from 'react-icons/ti'

const useContact = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulContacto {
        edges {
          node {
            direccin1
            direccin2
            telfono
            email
            instagramUrl
            pinterestUrl
            facebookUrl
          }
        }
      }
    }
  `);

  return data.allContentfulContacto.edges[0].node;
};

const XlFooter = () => {
  const data = useContact();

  return (
    <div className="xl-footer">
      <div className="xl-top-footer">
        <div className="text-center">
          <p>
            {data.direccin1}<br/>
            {data.direccin2}<br/>
            España
          </p>
        </div>

        <div className="text-center">
          <p style={{ textDecoration: "none", color: "#47535B" }}>
            {data.telfono}<br/>{data.email}</p>
        </div>

        <div className="xl-social-media">
          
          <div>
            <a href={data.instagramUrl} target="_blank" rel="noreferrer"
              style={{marginLeft: 18, marginRight: 18}}
            >
              <IoLogoInstagram fontSize="2.3em" color="#47535B"/>
            </a>
          </div>
          <div>
            <a href={data.pinterestUrl} target="_blank" rel="noreferrer">
              <TiSocialPinterest fontSize="2.5em" color="#47535B"/>
            </a>
          </div>
        </div>

      </div>

      <div className="powered-container">
        <div className="registered-attomo">
          <a href="https://attomo.digital/" target="_blank" rel="noreferrer">
            <p>
              Powered by
            </p>
            <div style={{ width: 54, marginLeft: 4, backgroundColor: "transparent"}}>
              <StaticImage
                src="../../images/ATTOMO_BLACK.png"
                alt="Attomo"
                placeholder="none"
                style={{
                  zIndex: 1,
                }}
              />
            </div>
          </a>
        </div>
      </div>

      <div className="xl-bottom-footer">
        <Link className="item-legal-footer" style={{marginRight: 10}} to="/privacidad">Política de privacidad</Link>
        <Link className="item-legal-footer" to="/aviso-legal">Aviso legal</Link>
      </div>
    </div>
  )
}

export default XlFooter
