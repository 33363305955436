import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import { IoLogoInstagram } from 'react-icons/io'
import { TiSocialPinterest } from 'react-icons/ti'
import XlFooter from "./XlFooter";
import "../Social/social.scss";
import "./Footer.scss";

const useContact = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulContacto {
        edges {
          node {
            direccin1
            direccin2
            telfono
            email
            instagramUrl
            pinterestUrl
            facebookUrl
          }
        }
      }
    }
  `);

  return data.allContentfulContacto.edges[0].node;
};

const Footer = () => {
  const data = useContact();

  return (
    <>
    <div className="Footer">
      <div className="footer-item">
          <p>
          {data.direccin1}<br/>
          {data.direccin2}<br/>
          España
          </p>
      </div>

      <div className="footer-item">
        <p style={{ textDecoration: "none", color: "#47535B" }}>
          {data.telfono}<br/>{data.email}</p>
      </div>

      <div className="social-footer">
      
        <div>
          <a href={data.instagramUrl} target="_blank" rel="noreferrer">
            <IoLogoInstagram fontSize="2.3em" color="#47535B"/>
          </a>
        </div>
        <div>
          <a href={data.pinterestUrl} target="_blank" rel="noreferrer">
            <TiSocialPinterest fontSize="2.5em" color="#47535B"/>
          </a>
        </div>

      </div>


      <div className="bottom-footer footer-item">
        <div className="registered-attomo">
          <a href="https://attomo.digital/" target="_blank" rel="noreferrer">
            <p>
              Powered by
            </p>
            <div style={{ width: 54, marginLeft: 4, backgroundColor: "transparent" , zIndex: 0}}>
              <StaticImage
                src="../../images/ATTOMO_BLACK.png"
                alt="Attomo"
                placeholder="none"
                style={{
                  zIndex: 1
                }}
              />
            </div>
          </a>
        </div>
      </div>

      <div className="legal-footer">
        <Link className="item-legal-footer" to="/aviso-legal">Aviso legal</Link>
        <Link className="item-legal-footer" to="/privacidad">Política de privacidad</Link>
      </div>
    </div>
    <XlFooter />            
    </>
  );
};

export default Footer;
