import React from 'react'
import { Link } from "gatsby";
import "./ContentXs.scss";

const paths = ["Proyectos","Estudio", "Reportajes"];

const ContentXs = ({ onClick }) => {
  return (
    <div className="menu-xs">
      {paths.map((value, i) => (
        <Link
          key={i}
          className="menu-xs-item"
          to={`/${value.toLowerCase()}`}
          {...(onClick ? { onClick: () => onClick(`/${value.toLowerCase()}`) } : {})}
        >
          <h2>{value}</h2>
        </Link>
      ))}
    </div>
  )
}

export default ContentXs
